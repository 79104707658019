import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { PERIODS_IN_HOURS_BY_KEY } from 'components';
import { PeriodKeys } from 'components/PeriodSelector/Types';
import { ZoneOverviewActivityDocument } from 'graphql/v2/ZonesPage/ZonePage/__generated__/ZoneOverviewActivity.query.generated';
import { ZoneOverviewActivityFromIbcDocument } from 'graphql/v2/ZonesPage/ZonePage/__generated__/ZoneOverviewActivityFromIBC.query.generated';

export type ZoneOverviewActivityQueryResult = {
  ibcVolume?: any;
  ibcVolumeIn?: any;
  ibcVolumeOut?: any;
  ibcVolumeInPercent?: any;
  ibcVolumeOutPercent?: any;
  ibcTransfers?: number;
  dau?: number | null;
  ibcDau?: number | null;
  totalTxs?: number;
};

export function useZoneOverviewActivity(period: PeriodKeys): {
  data: ZoneOverviewActivityQueryResult | undefined;
  loading: boolean;
} {
  const { zone = '' } = useParams();

  const options = {
    variables: { zone, period: PERIODS_IN_HOURS_BY_KEY[period], isMainnet: true },
    skip: !zone,
  };

  const { data, loading } = useQuery(ZoneOverviewActivityDocument, options);
  const { data: ibcData, loading: ibcLoading } = useQuery(
    ZoneOverviewActivityFromIbcDocument,
    options
  );

  console.log(data?.switchedStats?.ibcVolumeInPercent);
  const percentIn =
    (ibcData?.switchedStats[0]?.ibcVolumeIn / ibcData?.switchedStats[0]?.ibcVolume) * 100;
  const percentOut =
    (ibcData?.switchedStats[0]?.ibcVolumeOut / ibcData?.switchedStats[0]?.ibcVolume) * 100;

  console.log(percentIn);
  console.log(percentOut);

  const isLoading = loading || ibcLoading;
  return {
    data: data && {
      ibcVolume: ibcData?.switchedStats[0]?.ibcVolume,
      ibcVolumeIn: ibcData?.switchedStats[0]?.ibcVolumeIn,
      ibcVolumeOut: ibcData?.switchedStats[0]?.ibcVolumeOut,
      ibcVolumeInPercent: percentIn,
      ibcVolumeOutPercent: percentOut,
      ibcTransfers: data?.switchedStats?.ibcTransfers,
      totalTxs: data.stats[0]?.totalTxs,
      dau: data.stats[0]?.dau,
      ibcDau: data.stats[0]?.ibcDau,
    },
    loading: isLoading,
  };
}
